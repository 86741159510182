import "./App.scss";
import './Css/style.css';
import './Css/responsive.css';
import "./scripts";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import React, { Component } from "react";
import Cookies from "universal-cookie";
import appConstants from "./config/AppConstants";
import { ToastContainer } from "react-toastify";
import Loader from "./components/Loader";
const StudentLayout = React.lazy(() => import("./components/Layout/StudentLayout"));
const VerifyEmail = React.lazy(() => import("./components/Common/VerifyEmail"));
const ForgotPassword = React.lazy(() => import("./components/Common/newForgotPassword"));
const ResetPassword = React.lazy(() => import("./components/Common/newResetPassword"));
const NewLogin = React.lazy(() => import("./components/Common/newLogin"));
const SuperAdminLogin = React.lazy(() => import("./components/Common/superAdminLogin"));

const loading = () => <div className="custm-loader"><Loader /></div>;
const cookie = new Cookies();
class App extends Component {

  constructor(props) {
    super(props);
    const sessionUser = cookie.get("user", { path: "/" }),
      jwtSession = cookie.get("token", { path: "/" }),
      loginType = parseFloat(cookie.get("loginType", { path: "/" }));
    this.state = {
      user: sessionUser || null,
      token: jwtSession || null,
      loginType: loginType || null,
    };
  }

  render() {
    let { user, loginType } = this.state;
    let role = (user && user.role && user.role.name) || "";
    return (
      <>
        <ToastContainer />
        <div id="app-main-loader">
          <div className="loader-wrapper">
            <img src="img/main-loader.gif" alt="" />
          </div>
        </div>
        <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" component={NewLogin} />
              <Route exact path="/admin/login"><Redirect to={"/login"} /></Route>
              <Route exact path="/super-admin/login" component={SuperAdminLogin} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route exact path="/verify-email/:type/:userId/:token" component={VerifyEmail} />
              <Route exact path="/forgotPassword/:type/:userId/:token" component={ResetPassword} />
              <Route
                path="/"
                render={(props) => {
                  const isLoggedIn = this.state.token !== null;
                  const allowedRoles = [
                    appConstants.loginType.STUDENT,
                    appConstants.loginType.FACULTY,
                    appConstants.ROLES.trainer.name,
                    appConstants.ROLES.evaluator.name,
                    appConstants.ROLES.counsellor.name,
                  ];
                  const isAllowed = allowedRoles.includes(String(loginType)) || allowedRoles.includes(role);
                  return isLoggedIn && isAllowed ? <StudentLayout {...props} /> : <Redirect to="/login" />;
                }}
              />
            </Switch>
          </React.Suspense>
        </Router>
      </>
    );
  }
}

export default App;
